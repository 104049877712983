// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyDDKgC-zgLI5WZrRzb517rQNnUoSvgtads",
    authDomain: "metro-tech.firebaseapp.com",
    databaseURL: "https://metro-tech.firebaseio.com",
    projectId: "metro-tech",
    storageBucket: "metro-tech.appspot.com",
    messagingSenderId: "96772177794",
    appId: "1:96772177794:web:a7367c4f73a65a98b5d99b",
    measurementId: "G-VXFSW7KVQW"
  },
  BaseUrl:"https://mtsadminbackend.com:3200/",
  // BaseUrl:"http://34.239.106.112:3002/",
  // BaseUrl:"http://localhost:3200/",
  heading: "Administrative System"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
